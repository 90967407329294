import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
} from "@ionic/react";

import "./Home.css";
import MainLogo from "../images/mainLogo.png";
import CTOImg from "../images/cto.png";
import WhatsappLogo from "../images/whatsApp.png";
import Classroom from "../images/classroom.jpg";
import officeFront from "../images/office.jpg";
import inaugration from "../images/inaugration.jpg";
import Banner from "../images/banner.jpg";
import courseOffering from "../images/InfoPamphalet.jpeg";
import SmallBanner from "../images/smallBanner.jpeg";
import OnlineModeBanner from "../images/onlineModeBanner.jpeg";
import Pamphalet from "../images/pamphalet.jpeg";

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen className="mainBackground">
        <IonRow>
          <IonCol sizeXl="8" offsetXl="2">
            <IonCard mode="ios" className="mainSloganCard">
              <IonCardHeader className="mainSloganCardFooter">
                <IonRow>
                  <IonCol sizeMd="6" offsetMd="3" sizeXs="8" offsetXs="2">
                    <IonImg src={MainLogo} />
                  </IonCol>
                </IonRow>
              </IonCardHeader>
              <IonCardHeader className="ion-no-padding ion-no-margin">
                <IonRow>
                  <IonCol
                    sizeLg="6"
                    sizeMd="5.5"
                    sizeXs="12"
                    className="ion-no-padding"
                  >
                    <IonImg
                      className="ctoImage ion-margin-top"
                      src={CTOImg}
                    ></IonImg>
                  </IonCol>
                  <IonCol
                    sizeLg="6"
                    sizeMd="6.5"
                    sizeXs="12"
                    className="ion-padding"
                  >
                    <div>
                      <h1 className="mainSlogan">
                        <div className="waviy">
                          <span id="shaping1">S</span>
                          <span id="shaping2">H</span>
                          <span id="shaping3">A</span>
                          <span id="shaping4">P</span>
                          <span id="shaping5">I</span>
                          <span id="shaping6">N</span>
                          <span id="shaping7">G</span>
                        </div>
                      </h1>
                      <h1 className="mainSlogan">
                        <div className="waviy">
                          <span id="career1">C</span>
                          <span id="career2">A</span>
                          <span id="career3">R</span>
                          <span id="career4">E</span>
                          <span id="career5">E</span>
                          <span id="career6">R</span>
                          <span id="career7">S</span>
                        </div>
                      </h1>
                      <h1 className="mainSlogan">
                        <div className="waviy">
                          <span id="with1">W</span>
                          <span id="with2">I</span>
                          <span id="with3">T</span>
                          <span id="with4">H</span>
                        </div>
                      </h1>
                      <h1 className="mainSlogan">
                        <div className="waviy">
                          <span id="tech1">T</span>
                          <span id="tech2">E</span>
                          <span id="tech3">C</span>
                          <span id="tech4">H</span>
                          <span id="tech5">N</span>
                          <span id="tech6">O</span>
                          <span id="tech7">L</span>
                          <span id="tech8">O</span>
                          <span id="tech9">G</span>
                          <span id="tech10">I</span>
                          <span id="tech11">C</span>
                          <span id="tech12">A</span>
                          <span id="tech13">L</span>
                        </div>
                      </h1>
                      <h1 className="mainSlogan">
                        <div className="waviy">
                          <span id="edu1">E</span>
                          <span id="edu2">D</span>
                          <span id="edu3">U</span>
                          <span id="edu4">C</span>
                          <span id="edu5">A</span>
                          <span id="edu6">T</span>
                          <span id="edu7">I</span>
                          <span id="edu8">O</span>
                          <span id="edu9">N</span>
                          <span id="edu10"></span>
                          <span id="edu11">&</span>
                        </div>
                      </h1>
                      <h1 className="mainSlogan">
                        <div className="waviy">
                          <span id="dev1">D</span>
                          <span id="dev2">E</span>
                          <span id="dev3">V</span>
                          <span id="dev4">E</span>
                          <span id="dev5">L</span>
                          <span id="dev6">O</span>
                          <span id="dev7">P</span>
                          <span id="dev8">M</span>
                          <span id="dev9">E</span>
                          <span id="dev10">N</span>
                          <span id="dev11">T</span>
                        </div>
                      </h1>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "-40px" }}>
          <IonCol sizeXl="8" offsetXl="2">
            <IonCard mode="ios" className="mainSloganCard">
              <IonCardHeader style={{ background: "#282c34" }}>
                <IonRow className="whatsappContact">
                  <IonImg src={WhatsappLogo} className="whatsappLogo"></IonImg>
                  <h1 className="companyContact">
                    <span>+91 9149352344</span>
                  </h1>
                </IonRow>
              </IonCardHeader>
              <IonCardHeader>
                <IonLabel className="mainSloganFooterText">
                  INDIA<sup>s</sup> MOST AFFORDABLE TECH COURSE CERTIFICATIONS
                </IonLabel>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "-40px" }}>
          <IonCol sizeXl="8" offsetXl="2">
            <IonCard mode="ios" className="sessionModeCard">
              <IonCardHeader style={{ background: "#282c34" }}>
                <IonLabel className="mainSloganFooterText">
                  CHOOSE YOUR OWN LEARNING MODE <br />
                  WE OFFER
                </IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonCardTitle className="ion-text-center ion-margin-top">
                  <span>
                    <span className="sessionModeText">Online Mode </span>
                    <br />
                    <span className="sessionModeTextAnd">&</span>
                    <br />
                    <span className="sessionModeText">Offline Mode</span>
                  </span>
                </IonCardTitle>
              </IonCardContent>
              <IonCardHeader style={{ background: "#282c34" }}>
                <IonLabel className="mainSloganFooterText">
                  Google Meet Required For Online Live Sessions
                </IonLabel>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "-40px" }}>
          <IonCol sizeXl="8" offsetXl="2">
            <IonCard mode="ios" className="sessionModeCard">
              <IonCardHeader style={{ background: "#282c34" }}>
                <IonLabel className="mainSloganFooterText">
                  WHO CAN ENROLL ? <br />
                  LEARNING HAS NO AGE
                </IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonCardTitle className="ion-text-center ion-margin-top">
                  <span>
                    <span className="sessionModeText">11th & 12th</span>
                    <br />
                    <span className="sessionModeTextAnd">+</span>
                    <br />
                    <span className="sessionModeText">
                      Any Stream Gradudates
                    </span>
                    <br />
                    <span className="sessionModeText">
                      Special Courses for <br />
                      Btech, Bca, Mtech, Mca
                    </span>
                    <br />
                    <span className="sessionModeTextRed">#getcertified</span>
                  </span>
                </IonCardTitle>
              </IonCardContent>
              <IonCardHeader style={{ background: "#282c34" }}>
                <IonLabel className="mainSloganFooterText">
                  JavaScript, Typescript & Python are Trending in 2023
                </IonLabel>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "-40px" }}>
          <IonCol sizeXl="8" offsetXl="2">
            <IonCard mode="ios" className="sessionModeCard">
              <IonCardHeader style={{ background: "#282c34" }}>
                <IonLabel className="mainSloganFooterText">
                  OUR SPECIALIZED DOMAINS
                </IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol>
                    <IonList
                      mode="ios"
                      inset={true}
                      style={{ borderRadius: "12px" }}
                    >
                      <IonListHeader lines="inset">
                        <IonLabel className="domainLabel">
                          Tech Domains
                        </IonLabel>
                      </IonListHeader>
                      <IonItem color="primary">
                        <IonLabel className="domainLabel">Frontend</IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">Backend</IonLabel>
                      </IonItem>
                      <IonItem color="primary">
                        <IonLabel className="domainLabel">Fullstack</IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">
                          Web Development
                        </IonLabel>
                      </IonItem>
                      <IonItem color="primary">
                        <IonLabel className="domainLabel">
                          Mobile Development
                        </IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">
                          Micro Services
                        </IonLabel>
                      </IonItem>
                      <IonItem color="primary">
                        <IonLabel className="domainLabel">Rest APIs</IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">Database</IonLabel>
                      </IonItem>
                      <IonItem color="primary">
                        <IonLabel className="domainLabel">Cloud</IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">
                          Interview Preparation
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonList
                      mode="ios"
                      inset={true}
                      style={{ borderRadius: "12px" }}
                    >
                      <IonListHeader lines="inset">
                        <IonLabel className="domainLabel">
                          Our Offerings
                        </IonLabel>
                      </IonListHeader>
                      <IonItem color="tertiary">
                        <IonLabel className="domainLabel">JavaScript</IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">TypeScript</IonLabel>
                      </IonItem>
                      <IonItem color="tertiary">
                        <IonLabel className="domainLabel">Python</IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">
                          C / C++ / C#
                        </IonLabel>
                      </IonItem>
                      <IonItem color="tertiary">
                        <IonLabel className="domainLabel">PHP</IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">Dart</IonLabel>
                      </IonItem>
                      <IonItem color="tertiary">
                        <IonLabel className="domainLabel">
                          Angular / React JS / Vue JS
                        </IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">Node JS</IonLabel>
                      </IonItem>
                      <IonItem color="tertiary">
                        <IonLabel className="domainLabel">
                          Express JS / NEXT JS
                        </IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">Flutter</IonLabel>
                      </IonItem>
                      <IonItem color="tertiary">
                        <IonLabel className="domainLabel">
                          Ionic Framework
                        </IonLabel>
                      </IonItem>
                      <IonItem color="warning">
                        <IonLabel className="domainLabel">
                          Flask / Django
                        </IonLabel>
                      </IonItem>
                      <IonItem color="tertiary">
                        <IonLabel className="domainLabel">
                          HTML/CSS/SCSS
                        </IonLabel>
                      </IonItem>
                      <IonItem color="danger">
                        <IonLabel className="domainLabel">
                          And 35+ More Technologies...
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "-40px" }}>
          <IonCol sizeXl="8" offsetXl="2">
            <IonCard mode="ios" className="sessionModeCard">
              <IonCardHeader style={{ background: "#282c34" }}>
                <IonLabel className="mainSloganFooterText">
                  SPECIAL GLIMPS
                </IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol size="12">
                    <IonImg className="officePhoto" src={Banner}></IonImg>
                  </IonCol>
                  <IonCol size="12">
                    <IonImg className="officePhoto" src={officeFront}></IonImg>
                  </IonCol>
                  <IonCol size="12">
                    <IonImg className="officePhoto" src={inaugration}></IonImg>
                  </IonCol>
                  <IonCol size="12">
                    <IonImg className="officePhoto" src={Classroom}></IonImg>
                  </IonCol>
                  <IonCol size="12">
                    <IonImg
                      className="officePhoto"
                      src={courseOffering}
                    ></IonImg>
                  </IonCol>
                  <IonCol size="12">
                    <IonImg className="officePhoto" src={SmallBanner}></IonImg>
                  </IonCol>
                  <IonCol size="12">
                    <IonImg
                      className="officePhoto"
                      src={OnlineModeBanner}
                    ></IonImg>
                  </IonCol>
                  <IonCol size="12">
                    <IonImg className="officePhoto" src={Pamphalet}></IonImg>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Home;
